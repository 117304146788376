

















































































import { Component, Vue } from 'vue-property-decorator'
import axios, { AxiosPromise } from 'axios'
import store from '../store'
import router from '../router'

@Component
export default class Login extends Vue {
    private data: any = {
        credentials: {
            username: '',
            password: '',
        },
        pageId: this.pageId,
    };
    private loading = false;
    private error = false;
    private message = '';

    private get pageId(): number {
        return store.state.page.id
    }

    private login(): AxiosPromise {
        return axios.post('https://bundk-api.bdrops.space/api/login', this.data)
    }

    private loginUser(): void {
        this.error = false
        this.message = ''
        this.loading = true
        this.login().then((response) => {
            store.commit('setUser', response.data)
            window.localStorage.setItem(
                'bundk_user',
                JSON.stringify(response.data),
            )
            router.push({ path: '/' })
        }, (error) => {
            this.error = true
            this.message = 'Leider gab es ein Problem beim Login. Bitte versuchen Sie es später noch einmal.'
            if (error.response) {
                const status = error.response.status
                switch (status) {
                    case 401:
                        this.message = 'Der eingegebene Benutzername oder das Kennwort ist falsch!'
                        break
                    case 403:
                        this.message = 'Sie haben Ihr Benutzerkonto noch nicht aktiviert.'
                        break
                    case 404:
                        this.message = 'Sie haben keine Zugriffsberechtigung für diese Seite!'
                        break
                }
            }
        }).then(() => {
            this.loading = false
        })
    }
}
